import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import "../index.css";
const TermComponent = () => {
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <div>
      <Button variant="primary" onClick={handleShowModal} className="term-btn">
        <h4 className="underlined-text"> *Terms and conditions here </h4>
      </Button>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title className="modal-title">
            <span className="english-title">T&Cs :</span>
            <span className="arabic-title">الشروط والأحكام:</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ul>
            <li>This voucher is valid for Dine-in only.</li>
            <li>
              This voucher cannot be used in conjunction with any other discount
              or promotion.
            </li>
            <li>This voucher is only valid at Abu Dhabi Al Dana Al Muroor Road location.</li>
            <li>Only one voucher can be used per table.</li>
            <li>This voucher can be used one time only.</li>
            <li>Expiry: 30/09/2023</li>
          </ul>
          <ul className="arabic">
            <li>هذه القسيمة صالحة عند الطلب داخل المطعم.</li>
            <li>لا يمكن استخدام هذه القسيمة مع أي خصم أو عرض آخر.</li>
            <li>هذه القسيمة صالحة فقط في فرع الدانة شارع المرور في أبو ظبي.</li>
            <li>يمكن استخدام قسيمة واحدة فقط لكل طاولة.</li>
            <li>انتهاء الصلاحية: ٣٠/٠٩/٢٠٢٣</li>
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default TermComponent;
