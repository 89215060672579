import React, { useEffect, useState } from "react";
// import CountdownTimer from "./countdownTimer";
import "../index.css";
import img from "../../img/OF_Opening_QR-03.png";
import img2 from "../../img/OF_Opening_QR-04.png";
// import img5 from "../../img/OF_Opening_QR-05.png";
// import img6 from "../../img/OF_10K_AD_Aug23_QR-02-07.png";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format, parse } from "date-fns";
import TermComponent from "./term.component";
import DoneComponent from "../message/done.component";

import axios from "axios";
import Swal from "sweetalert2";
import { unset } from "lodash";

const Form = ({ id, nationalities }) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
    number: "",
    gender: "", // Default value
    dob: "",
    slug: id,
    outlet_id: 1,
    date_of_birth: "",
    nationality_id: "",
  });

  const [showDone, setShowDone] = useState(false);
  const [msg, setMsg] = useState("done");
  const [isValidNumber, setIsValidNumber] = useState(true);
  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isValid = /^\d{10}$/.test(formData.number);
    setIsValidNumber(isValid);
    const numberString = formData.number.toString();
    formData.nationality_id = parseInt(formData.nationality_id);
    if (numberString.charAt(0) === "0") {
      formData.mobile = parseInt(numberString.substring(1), 10).toString();
    }
    // console.log(formData);
    try {
      const keysToDelete = ["dob", "number"];
      const newData = { ...formData };
      keysToDelete.forEach((key) => {
        unset(newData, key);
      });
      const response = await axios.post(
        "https://backend-landingpage.azurewebsites.net/customers/add-customer",
        newData
      );
      if ("data" in response && "body" in response.data) {
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: response.data.body,
        });
        setMsg(response.data.body);
        setTimeout(() => {
          setShowDone(true);
        }, 5000);
      }

      // console.log("Response:", response.data);
    } catch (error) {
      if (
        "response" in error &&
        "data" in error.response &&
        "body" in error.response.data
      )
        Swal.fire({
          icon: "error",
          title: "Error!",
          text: error.response.data.body,
        });
      // console.log(error.response.data);
      // console.error("Error:", error);
    }
  };

  const handleDateChange = (date) => {
    // console.log(date);
    const formattedDateStr = format(date, "yyyy-MM-dd");
    const formattedDate = parse(formattedDateStr, "yyyy-MM-dd", new Date());

    setFormData((prevData) => ({
      ...prevData,
      dob: formattedDate,
      date_of_birth: formattedDateStr,
    }));
  };

  useEffect(() => {}, [nationalities]);

  return (
    <div>
      {!showDone ? (
        <div className="container">
          {/* <img src={img5} alt="" className="arrow" />  */}
          <div className="row">
            <div className="col-md-12">
              {/* <CountdownTimer /> */}
              
              {/* <img src={img6} alt="" className="img6" /> */}
              <div className="form-container">
                <img src={img} alt="" className="form-img1" />
                <img
                  src={img2}
                  alt=""
                  className="form-img1"
                  style={{ marginBottom: "20%" }}
                />

                <h4 className="form-label">Fill your details to enter:</h4>

                <form onSubmit={handleSubmit}>
                  <div className="form-row">
                    <label className="form-label">
                      Name*:
                      <span className="right-label">:الإسم</span>
                    </label>
                    <input
                      type="text"
                      name="name"
                      placeholder="Enter your name"
                      value={formData.name}
                      onChange={handleChange}
                      className="input-field"
                      required
                    />
                  </div>
                  <div className="form-row">
                    <label className="form-label">
                      Mobile*:
                      <span className="right-label">:الرقم</span>
                    </label>
                    <input
                      type="number"
                      name="number"
                      placeholder="Eg.0503214569"
                      value={formData.number}
                      onChange={handleChange}
                      className="input-field"
                      required
                    />
                    {!isValidNumber && (
                      <p style={{ color: "red" }}>
                        Please enter a valid phone number.
                      </p>
                    )}
                  </div>
                  <div className="form-row">
                    <label className="form-label">
                      Email*:
                      <span className="right-label">: البريد الإلكتروني</span>
                    </label>
                    <input
                      type="email"
                      name="email"
                      placeholder="Eg. John@gmail.com"
                      value={formData.email}
                      onChange={handleChange}
                      className="input-field"
                      required
                    />
                  </div>
                  <div className="form-row">
                    <label className="form-label">
                      Nationality*:
                      <span className="right-label">: الجنسية</span>
                    </label>
                    <select
                      className="  input-field"
                      required
                      onChange={handleChange}
                      name="nationality_id"
                      defaultValue="0"
                    >
                      <option value="0" disabled>
                        Nationality
                      </option>
                      {nationalities?.map((nationality) => (
                        <option key={nationality.id} value={nationality.id}>
                          {nationality.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="form-row">
                    <label className="form-label">
                      Gender*:
                      <span className="right-label">: الجنس</span>
                    </label>
                    <div className="radio-div">
                      <label className="radio-label">
                        <input
                          type="radio"
                          name="gender"
                          value="male"
                          className="radio-radio"
                          checked={formData.gender === "male"}
                          onChange={handleChange}
                          required
                        />
                        Male
                      </label>
                      <label className="radio-label">
                        <input
                          type="radio"
                          name="gender"
                          value="female"
                          className="radio-radio"
                          checked={formData.gender === "female"}
                          onChange={handleChange}
                          required
                        />
                        Female
                      </label>
                    </div>
                  </div>
                  <div className="form-row">
                    <label className="form-label">
                      Date of Birth*:
                      <span className="right-label">:الميلاد</span>
                    </label>
                    {/* <input
                    type="date"
                    name="dob"
                    value={formData.date_of_birth}
                    onChange={handleChange}
                    className="input-field"
                    required
                  /> */}
                    <DatePicker
                      id="datepicker"
                      selected={formData.dob}
                      onChange={handleDateChange}
                      showYearDropdown
                      scrollableYearDropdown
                      yearDropdownItemNumber={100}
                      dateFormat="dd/MM/yyyy"
                      className="input-field"
                      placeholderText="Click to select a date"
                      required
                    />
                  </div>
                  <button type="submit" className="submit">
                    Submit
                  </button>
                </form>
              </div>
            </div>
            <div className="col-md-12 mb-10">
              <TermComponent />
            </div>
          </div>
        </div>
      ) : (
        <DoneComponent  msg={msg}/>
      )}
    </div>
  );
};

export default Form;
